export const contactInformation = {
    phoneNumberHeader: '+38 (067)350 42 42',
    phoneNumberFooterTop: '+38 (067)350 42 42',
    phoneNumberFooyerBottom: '+38 (067) 354 32 74',
    mail: 'skala_speckar@ukr.net',
    ceoPhoneNumber: '+38 (067)350 42  42',
    financialDepartmentPhoneNumber: '+38 (067)354 32 74',
    supplyDepartmentPhoneNumber: '+38 (067)350 42 42',
    salesDepartmentPhoneNumber: '+38 (067)354 32 74',
}
